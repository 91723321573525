:root {
  --white: #fff;
  --black: #000;
}

:root {
  --gray-50: #eaf3ff;
  --gray-100: #ced8e4;
  --gray-200: #b1becd;
  --gray-300: #94a3b8;
  --gray-400: #7689a2;
  --gray-500: #5d7089;
  --gray-600: #47576b;
  --gray-700: #323e4e;
  --gray-800: #1d2531;
  --gray-900: #040d17;
}

:root {
  --red-50: #ffe4e4;
  --red-100: #feb5b6;
  --red-200: #f88687;
  --red-300: #f45757;
  --red-400: #f02928;
  --red-500: #d7110f;
  --red-600: #a80b0b;
  --red-700: #790607;
  --red-800: #4a0203;
  --red-900: #1f0000;
}

:root {
  --orange-50: #ffefde;
  --orange-100: #fdd5b4;
  --orange-200: #f7ba88;
  --orange-300: #f19f5a;
  --orange-400: #ec832c;
  --orange-500: #d36a13;
  --orange-600: #a5520d;
  --orange-700: #763a07;
  --orange-800: #482200;
  --orange-900: #1d0a00;
}

:root {
  --yellow-50: #fff8de;
  --yellow-100: #f9eab6;
  --yellow-200: #f3dc8b;
  --yellow-300: #eecf5e;
  --yellow-400: #e9c131;
  --yellow-500: #d0a719;
  --yellow-600: #a18211;
  --yellow-700: #735d09;
  --yellow-800: #453802;
  --yellow-900: #191300;
}

:root {
  --green-50: #e2fbed;
  --green-100: #c2ebd4;
  --green-200: #9fddb9;
  --green-300: #7ccf9e;
  --green-400: #58c184;
  --green-500: #3ea76a;
  --green-600: #2e8251;
  --green-700: #1f5d3a;
  --green-800: #0f3921;
  --green-900: #001506;
}

:root {
  --teal-50: #defcfa;
  --teal-100: #beefec;
  --teal-200: #9ae1de;
  --teal-300: #74d4d0;
  --teal-400: #50c8c2;
  --teal-500: #37afa9;
  --teal-600: #278883;
  --teal-700: #17625e;
  --teal-800: #033b39;
  --teal-900: #001616;
}

:root {
  --blue-50: #e0f4ff;
  --blue-100: #b8dcfa;
  --blue-200: #8ec4f1;
  --blue-300: #63ace8;
  --blue-400: #3994e0;
  --blue-500: #1f7bc6;
  --blue-600: #135f9b;
  --blue-700: #084470;
  --blue-800: #002946;
  --blue-900: #000f1d;
}

:root {
  --cyan-50: #d9fdff;
  --cyan-100: #adf1ff;
  --cyan-200: #7fe7fb;
  --cyan-300: #50dcf8;
  --cyan-400: #24d2f5;
  --cyan-500: #0ab9db;
  --cyan-600: #0090ab;
  --cyan-700: #00677c;
  --cyan-800: #003f4c;
  --cyan-900: #00171c;
}

:root {
  --purple-50: #f0e8ff;
  --purple-100: #cfbcf8;
  --purple-200: #b091ef;
  --purple-300: #9065e7;
  --purple-400: #7039df;
  --purple-500: #5620c5;
  --purple-600: #43189a;
  --purple-700: #30116f;
  --purple-800: #1d0944;
  --purple-900: #0c011b;
}

:root {
  --pink-50: #ffe5f5;
  --pink-100: #fab8d9;
  --pink-200: #f38cbd;
  --pink-300: #ec5fa3;
  --pink-400: #e63389;
  --pink-500: #cd1b6f;
  --pink-600: #a01357;
  --pink-700: #730b3e;
  --pink-800: #470425;
  --pink-900: #1d000e;
}
