@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");
@import "~@startaideia/react-forms/dist/index.css";
@import "~suneditor/dist/css/suneditor.min.css";
@import "~normalize.css/normalize.css";
@import "~bootstrap-utilities";

@import "core";
@import "colors";
@import "spacing";

/* Fonts styles & sizes */
@import "fonts/sizes";
@import "fonts/weights";

.ck-editor__editable_inline {
  font-size: 12px;
  height: 250px;
}
