* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: "Ubuntu", sans-serif;
  background: #ebebeb;
  height: 100vh;
}
