.stf-default-theme {
  .stf-form-control {
    border-color: var(--gray-100);
    border-radius: 15px;
    padding: 10px 12px;
    font-size: 12px;
  }

  .stf-form-label {
    color: var(--gray-500);
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
  }

  .stf-dropdown-item {
    color: var(--gray-500);
  }

  .stf-form-button {
    border: 1px solid var(--gray-100);
    border-color: var(--pink-400);
    background: var(--pink-400);
    justify-content: center;
    display: inline-flex;
    color: var(--white);
    align-items: center;
    border-radius: 17px;
    padding: 0px 15px;
    transition: 0.25s;
    font-weight: 500;
    font-size: 12px;
    outline: none;
    height: 32px;
    width: auto;

    &:hover {
      cursor: pointer;
    }

    &:active {
      transform: scale(0.9);
    }
  }
}

.stf-clear-theme {
  .stf-form-control {
    background: transparent;
    border-radius: 0px;
    font-size: 12px;
    padding: 0px;
    border: 0px;

    &.focus {
      border: 0px;
    }

    &[group="title"] {
      text-transform: uppercase;
      color: var(--gray-700);
      font-weight: 500;
      font-size: 11px;
    }

    &[group="reference"] {
      color: var(--blue-500);
      font-size: 16px;
    }

    &[group="description"] {
      color: var(--gray-500);
      font-size: 14px;
    }
  }
  .stf-form-group {
    line-height: 0;
    font-size: 0;
    padding: 0;
  }
}
